import draggable from 'vuedraggable'
import { ACTION_TYPE_LIST } from '../../../misc/commons'
import supplierActionService from '../../../services/supplier/template-action'
import providerActionService from '../../../services/provider/organization'
import _ from 'lodash'
import noti from '../../../utils/noti_toast'
import store from '../../../store'
export default {
    components: {
        draggable,
    },
    props: {
        eventType: { type: String, default: 'RECALL'},
    },
    data() {
        return {
            actions: [],
            lasteditedIndex: null,
            description: '',
            actionType: null,
            options: ACTION_TYPE_LIST,
            isDragging: false,
            delayedDragging: false,
            info: null,
            isdisabled: false,
            recordexist: false,
            isMultiselect: false,
            isAllowOnlyOne: false,
            actionValues: [{ value: '' }],
            value1: null,
            orgType: store.state.userType,
            service: store.state.userType =='SUPPLIER' ? supplierActionService: providerActionService,
        }
    },
    mounted() {
        this.getInternalAction()
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                ghostClass: 'ghost',
                disabled: this.recallDisabled,
            }
        },
        nextOrder() {
            if (this.actions && this.actions.length > 0) {
                this.recordexist = true
                return (
                    Math.max.apply(
                        Math,
                        this.actions.map(function(o) {
                            return o.order
                        })
                    ) + 1
                )
            } else {
                this.isdisabled = true
                return 1
            }
        },
    },
    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true
                return
            }
            this.$nextTick(() => {
                this.delayedDragging = false
            })
        },
        actionType(val) {
            if (val) {
                this.isMultiselect = false
                if (val === 'MultipleChoice') {
                    this.isMultiselect = true
                    this.actionValues = []
                    this.actionValues.push({ value: null })
                }
            }
        },
    },
    methods: {
        getInternalAction() {

            this.service.findInternalActions(this.eventType).then((res) => {
                if (res.data.d.length == 0) {
                    this.isdisabled = true
                    this.recordexist = false
                } else {
                    this.recordexist = true
                }

                this.actionData = res.data.d

                this.actions = _.orderBy(
                    this.actionData,
                    ['sequence_number'],
                    ['asc']
                ).map((x, index) => {
                    return {
                        ...x,
                        order: index + 1,
                    }
                })
            })
        },
        addAction() {
            let valid  = true
            this.$refs['observerAddAction'].validate().then((isValid) => {
                if (isValid) {
                    var action = this.actions.filter(
                        (x) => x.description === this.description
                    )
                    if (action.length > 0) {
                        noti.error('Error', 'Duplicate action name cannot be used.')
                    } else {
                        let Arr = []
                        if(this.actionType == 'MultipleChoice')
                        {
                            let isValid = true
                            let vakl = this.value1

                            let DupCkList = []

                            if(this.value1 != null && this.value1 != undefined && this.value1 != '')
                            {
                                DupCkList.push(this.value1)
                            }
                            else{
                                noti.error('Error', 'Please add at least one option.')
                                return ''
                            }
                            for (let index = 0; index < this.actionValues.length; ++index) {
                                if(DupCkList.includes(this.actionValues[index].value))
                                {
                                    if(this.actionValues[index].value == this.value1)
                                    {
                                        this.value1 = null
                                    }
                                    isValid = false
                                }
                                DupCkList.push(this.actionValues[index].value)
                            }
                            this.actionValues.filter(function(elem){
                                if(elem.value == vakl)
                                {
                                    isValid = false
                                }
                            })
                            if(!isValid)
                            {
                                valid = false
                                noti.error('Error', 'Duplicate values cannot be entered.')
                                return ''
                            }
                            for (let index = 0; index < this.actionValues.length; ++index) {
                                if(this.actionValues.length == 1 && this.actionValues[index].value == '')
                                {
                                    if(this.value1 == '' || this.value1 == null)
                                    {
                                        valid = false
                                        noti.error('Error', 'Please add at least one option.')
                                    }
                                    else
                                    {
                                        this.actionValues.push({ value: this.value1 })
                                    }
                                }
                                if(this.actionValues[index].value != '' && this.actionValues[index].value != null)
                                {
                                    Arr.push(this.actionValues[index])
                                }
                            }
                            if(this.value1 != '' && this.value1 != null && this.value1 != undefined)
                            {
                                Arr.push({ value: this.value1 })
                            }
                            if(Arr.length == 0 && valid == true)
                            {
                                valid = false
                                this.actionValues = []
                                this.actionValues.push({ value: '' })
                                noti.error('Error', 'Please add at least one option.')
                            }
                        }

                        if(valid)
                        {
                            this.actions.push({
                                id: null,
                                description: this.description,
                                action_type: this.actionType,
                                order: this.nextOrder,
                                isAllowOnlyOne: this.isAllowOnlyOne,
                                eventType: this.eventType,
                                actionValues: this.actionType == 'MultipleChoice' ? Arr:this.actionValues//this.actionValues,
                            })
                            this.isdisabled = false
                        }
                    }
                    if(valid)
                    {
                        this.isAllowOnlyOne = false
                        this.isMultiselect = false
                        this.actionValues = []
                        this.actionValues.push({ value: '' })
                        this.value1 = []
                        this.description = ''
                        this.actionType = null
                        requestAnimationFrame(() => {
                            this.$refs.observerAddAction.reset()
                        })
                    }
                }
            })
        },
        removeAction(item) {
            if (this.actions && this.actions.length > 0) {
                const _index = this.actions.findIndex((x) => x.order === item.order)
                if (_index !== -1) {
                    this.actions.splice(_index, 1)
                }

                if (!this.recordexist && this.actions.length == 0) {
                    this.isdisabled = true
                }
            }
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            )
        },
        onEdit(item) {
            var action = this.actions.filter(
                (x) => x.description === item.description && x.order != item.order
            )
            if (action.length > 0) {
                this.lasteditedIndex = item.order
                setTimeout(() => {
                    var error = {}
                    error['description-' + item.order] = [
                        'Duplicate action name cannot be used',
                    ]
                    this.$refs.observerListAction.setErrors(error)
                }, 100)
            } else {
                requestAnimationFrame(() => {
                    this.$refs.observerListAction.reset()
                })
            }
        },
        add() {
            let isValid = true
            let vakl = this.value1
            this.actionValues.filter(function(elem){
                if(elem.value == vakl)
                {
                    isValid = false
                }
            })
            if(isValid)
            {
                if(this.value1 != '' && this.value1 != null)
                {
                    this.actionValues.push({ value: this.value1 })
                    this.value1 = null
                }
            }
            else
            {
                if(this.value1==null)
                {
                    noti.error('Error', 'Please add at least one option.')
                }
                else{
                    this.value1 = null
                    noti.error('Error', 'Duplicate values cannot be entered.')
                }

            }
        },
        remove(index) {
            this.actionValues.splice(index, 1)
        },
        saveactions() {
            let valid  = true
            for (let i = 0; i < this.actions.length; ++i) {
                if ( this.actions[i].action_type == 'MultipleChoice' )
                {
                    let isValid = true
                    let DupCkList = []
                    for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                        if(DupCkList.includes(this.actions[i].actionValues[index].value) && this.actions[i].actionValues[index].value != '')
                        {
                            if(this.actions[i].actionValues[index].value == this.value1)
                            {
                                this.value1 = null
                            }
                            isValid = false
                        }
                        if(this.actions[i].actionValues[index].value != '')
                        {
                            DupCkList.push(this.actions[i].actionValues[index].value)
                        }
                    }
                    if(!isValid)
                    {
                        valid = false
                        if(DupCkList.length == 0)
                        {
                            noti.error('Error', 'Please add at least one option.')
                        }
                        else
                        {
                            noti.error('Error', 'Duplicate values cannot be entered.')
                            return ''
                        }
                    }
                    let Arr = []
                    for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                        if(this.actions[i].actionValues[index].value != '')
                        {
                            Arr.push(this.actions[i].actionValues[index])
                        }
                    }
                    if(Arr.length == 0 && valid == true)
                    {
                        valid = false
                        noti.error('Error', 'Please add at least one option.')
                    }
                    else
                    {
                        this.actions[i].actionValues = []
                        this.actions[i].actionValues = Arr
                    }
                }
            }
            if(valid)
            {
                return new Promise((resolve, reject) => {
                    this.save()
                        .then((resp) => {
                            if (resp) {
                                this.loading = true
                                resolve(true)
                                this.getInternalAction()
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            }
        },
        save() {


            return new Promise((resolve, reject) => {
                if (this.actions && this.actions.length > 0) {
                    this.$refs['observerListAction'].validate().then((isValid) => {
                        if (isValid) {
                            let hasError = false
                            this.actions.forEach((act) => {
                                var action = this.actions.filter(
                                    (x) =>
                                        x.description === act.description && x.order != act.order
                                )
                                if (action.length > 0) {
                                    var error = {}
                                    error['description-' + act.order] = [
                                        'Duplicate action name cannot be used',
                                    ]
                                    this.$refs.observerListAction.setErrors(error)
                                    hasError = true
                                    return true
                                }
                            })
                            if (!hasError) {
                                requestAnimationFrame(() => {
                                    this.$refs.observerListAction.reset()
                                })
                                this.service.saveInternalActions(this.eventType, { actions: this.actions })
                                    .then((res) => {
                                        //return data: recall id to reload data
                                        if (!res.error) {
                                            resolve(res.data.d)
                                            this._showToast('Template Actions Updated Successfully')
                                        }
                                    })
                            } else {
                                reject('invalid actions')
                            }
                        } else {
                            reject('invalid actions')
                        }
                    })
                } else {
                    this.service.saveInternalActions(this.eventType, { actions: this.actions })
                        .then((res) => {
                            if (!res.error) {
                                resolve(res.data.d)
                                this._showToast('Template Actions Updated Successfully')
                            }
                        })
                }
            })
        },
    },
}
